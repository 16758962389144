import { useState } from 'react';
import { cn } from "@/lib/utils";

interface CategoryDropdownProps {
  onFilterChange: (selectedCategory: string | null) => void;
  initialCategory?: string;
}

export function CategoryDropdown({ onFilterChange, className }: CategoryDropdownProps & { className?: string }) {
  const [open, setOpen] = useState(false);
  const [selectedCategory, setSelectedCategory] = useState<string | null>(null);

  const categories = [
    "Natural Language Processing",
    "Machine Learning",
    "Computer Vision",
    "Deep Learning",
    "Reinforcement Learning",
    "Robotics",
    "Generative AI",
    "Neural Networks",
    "Speech Recognition",
    "Image Processing",
    "Data Mining",
    "Artificial Intelligence",
    "Expert Systems",
    "Natural Language Generation",
    "Chatbots",
    "Autonomous Vehicles",
  ];

  function selectCategory(category: string) {
    const newSelection = selectedCategory === category ? null : category;
    setSelectedCategory(newSelection);
    onFilterChange(newSelection);
    setOpen(false);
  }

  return (
    <select
      value={selectedCategory || ""}
      onChange={(e) => selectCategory(e.target.value)}
      className={cn("w-full p-2 border border-input rounded-md", className)}
    >
      <option value="">Select a category</option>
      {categories.map((category) => (
        <option key={category} value={category}>
          {category}
        </option>
      ))}
    </select>
  );
}