import React from 'react';
import { useTheme } from './theme-provider';

interface LogoProps {
  width?: number;
  height?: number;
  onClick?: () => void;
}

export function Logo({ width = 50, height = 50, onClick }: LogoProps) {
  const { theme } = useTheme();

  return (
    <div className="bg-white p-2 inline-block rounded-full" onClick={onClick}>
      <svg width={width} height={height} viewBox="0 0 200 200" fill="none" xmlns="http://www.w3.org/2000/svg" className="transition-transform duration-300 hover:animate-spin">
        <g id="logo" className={`${theme === 'dark' ? 'fill-black' : 'fill-black'}`}>
          <path d="M96 0H104V200H96V0Z" />
          <path d="M122.018 2.37213L129.746 4.44268L77.9818 197.628L70.2544 195.557L122.018 2.37213Z" />
          <path d="M146.536 11.3975L153.464 15.3975L53.4641 188.603L46.5359 184.603L146.536 11.3975Z" />
          <path d="M167.882 26.4609L173.539 32.1177L32.1177 173.539L26.4609 167.882L167.882 26.4609Z" />
          <path d="M184.886 46.9874L188.849 53.9368L15.1138 153.013L11.1508 146.063L184.886 46.9874Z" />
          <path d="M195.557 70.2544L197.628 77.9818L4.4427 129.746L2.37215 122.018L195.557 70.2544Z" />
          <path d="M200 96V104L0 104L3.49691e-07 96L200 96Z" />
          <path d="M197.628 122.018L195.557 129.746L2.37215 77.9818L4.44271 70.2544L197.628 122.018Z" />
          <path d="M188.603 146.536L184.603 153.464L11.3975 53.4641L15.3975 46.5359L188.603 146.536Z" />
          <path d="M173.539 167.882L167.882 173.539L26.4609 32.1178L32.1178 26.4609L173.539 167.882Z" />
          <path d="M153.464 184.603L146.536 188.603L46.5359 15.3975L53.4641 11.3975L153.464 184.603Z" />
          <path d="M129.746 195.557L122.018 197.628L70.2544 4.4427L77.9818 2.37215L129.746 195.557Z" />
        </g>
      </svg>
    </div>
  );
}