import { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { InnerView } from '@/components/ui/innerview';
import { Button } from '@/components/ui/button';
import { Helmet } from 'react-helmet';

interface CardData {
  id: string;
  title: string;
  description: string;
  categories: string;
  credit: string;
  date: string;
  url: string;
}

export default function InnerPage() {
  const { id } = useParams<{ id: string }>();
  const [cardData, setCardData] = useState<CardData | null>(null);
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState<string | null>(null);

  useEffect(() => {
    async function fetchCardData() {
      if (!id) return;

      setIsLoading(true);
      try {
        const response = await fetch(`https://api.playpanzer.com/mlboard/post/${id}`);
        if (!response.ok) {
          throw new Error('Failed to fetch card data');
        }
        const data: CardData = await response.json();
        setCardData(data);
      } catch (err) {
        setError('An error occurred while fetching the card data.');
        console.error(err);
      } finally {
        setIsLoading(false);
      }
    }

    fetchCardData();
  }, [id]);

  return (
    <div className="[&>section]:w-full [&>section]:py-[20dvh] lg:[&>section]:min-h-[75dvh]">
        {cardData && (
          <Helmet>
            <title>{`MLBoard - ${cardData?.title || "Loading..."}`}</title>
            <meta name="description" content={cardData?.description || "Loading..."} />
            <meta property="og:title" content={cardData.title || "MLBoard - Exploring Machine Learning"} />
            <meta property="og:description" content={cardData.description || "Discover the latest in machine learning, artificial intelligence, and deep learning."} />
            <meta property="og:type" content="article" />
            <meta name="twitter:card" content="summary_large_image" />
            <meta name="twitter:title" content={cardData.title || "MLBoard - Exploring Machine Learning"} />
            <meta name="twitter:description" content={cardData.description || "Discover the latest in machine learning, artificial intelligence, and deep learning."} />
            <meta name="keywords" content={`${cardData.categories || "machine learning, artificial intelligence"}, ${cardData.credit || "MLBoard"}, mlboard, machine learning, artificial intelligence`} />
            <meta name="author" content={cardData.credit || "MLBoard"} />
          </Helmet>
        )}
      <section className="flex flex-col flex-center">
        <h1 className="leading-tight text-center max-w-screen-lg mx-auto mb-8">
          <span>
            <span className="sr-only">
              Comprehensive resource for machine learning, artificial intelligence, and deep learning.
            </span>{" "}
            <span className="text-4xl tracking-tighter font-bold relative">
              <span className="relative z-10">{cardData?.title || "Loading..."}</span>
            </span>
          </span>
        </h1>
        <div className="flex justify-center">
          {isLoading && <p>Loading...</p>}
          {error && <p className="text-red-500">{error}</p>}
          {cardData && (
            <InnerView
              title={cardData.title}
              description={cardData.description}
              categories={cardData.categories}
              credit={cardData.credit}
              date={new Date(cardData.date)}
              url={cardData.url}
            />
          )}
        </div>
      </section>
    </div>
  );
}