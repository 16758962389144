import { useState } from 'react';
import { Button } from "@/components/ui/button";

interface CategoryFilterProps {
  onFilterChange: (selectedCategories: string[]) => void;
  activeCategories: string[];
}

const categoryColors = {
  "Natural Language Processing": "bg-blue-500",
  "Machine Learning": "bg-green-500",
  "Computer Vision": "bg-purple-500",
  "Deep Learning": "bg-red-500",
  "Reinforcement Learning": "bg-yellow-500",
  "Robotics": "bg-orange-500",
  "Generative AI": "bg-pink-500",
  "Neural Networks": "bg-indigo-500",
  "Speech Recognition": "bg-teal-500",
  "Image Processing": "bg-cyan-500",
  "Data Mining": "bg-lime-500",
  "Artificial Intelligence": "bg-amber-500",
  "Expert Systems": "bg-emerald-500",
  "Natural Language Generation": "bg-sky-500",
  "Chatbots": "bg-fuchsia-500",
  "Autonomous Vehicles": "bg-rose-500",
};


export function CategoryFilter({ onFilterChange, activeCategories }: CategoryFilterProps) {
  function toggleCategory(category: string) {
    const newActiveCategories = activeCategories.includes(category)
      ? activeCategories.filter((c) => c !== category)
      : [...activeCategories, category];
    onFilterChange(newActiveCategories);
  }

  return (
    <div className="flex flex-wrap gap-2">
      {Object.entries(categoryColors).map(([category, colorClass]) => (
        <Button
          key={category}
          onClick={() => toggleCategory(category)}
          className={`${colorClass} text-white text-xs px-4 py-1 rounded-md transition-all ${
            activeCategories.includes(category) 
              ? 'opacity-100' 
              : 'opacity-50'
          } hover:opacity-100`}
        >
          {category}
        </Button>
      ))}
    </div>
  );
}