import { Card, CardContent, CardDescription, CardFooter, CardHeader, CardTitle } from "@/components/ui/card"
import { Button } from "@/components/ui/button"
import { Link } from "react-router-dom"
import { useEffect, useState } from "react";
import { motion, AnimatePresence } from "framer-motion";

const categoryColors = {
  "Natural Language Processing": "bg-blue-500",
  "Machine Learning": "bg-green-500",
  "Computer Vision": "bg-purple-500",
  "Deep Learning": "bg-red-500",
  "Reinforcement Learning": "bg-yellow-500",
  "Robotics": "bg-orange-500",
  "Generative AI": "bg-pink-500",
  "Neural Networks": "bg-indigo-500",
  "Speech Recognition": "bg-teal-500",
  "Image Processing": "bg-cyan-500",
  "Data Mining": "bg-lime-500",
  "Artificial Intelligence": "bg-amber-500",
  "Expert Systems": "bg-emerald-500",
  "Natural Language Generation": "bg-sky-500",
  "Chatbots": "bg-fuchsia-500",
  "Autonomous Vehicles": "bg-rose-500",
  "Recommender Systems": "bg-violet-500",
  "Sentiment Analysis": "bg-slate-500",
  "Facial Recognition": "bg-gray-500",
  "Quantum Machine Learning": "bg-zinc-500",
  "Quantum Computing": "bg-stone-500"
}

interface CardData {
  id: string;
  title: string;
  url: string;
  description?: string;
  categories?: string;
  date: string;
  credit?: string;
}

export default function Component({ activeCategories }: { activeCategories: string[] }) {
  const [cards, setCards] = useState<CardData[]>([]);
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState<string | null>(null);
  const [page, setPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);

  const fetchCards = async (pageNumber: number) => {
    try {
      const queryParams = new URLSearchParams({
        categories: activeCategories.join(','),
        page: pageNumber.toString(),
        limit: '10'
      });
      const response = await fetch(`https://api.playpanzer.com/mlboard/posts?${queryParams}`);
      if (!response.ok) {
        throw new Error('Failed to fetch cards');
      }
      const data = await response.json();
      return data;
    } catch (err) {
      throw err;
    }
  };

  useEffect(() => {
    const loadInitialCards = async () => {
      try {
        const { currentPage, posts, totalPages } = await fetchCards(1);
        setCards(posts);
        setPage(currentPage);
        setTotalPages(totalPages);
      } catch (err) {
        setError(err instanceof Error ? err.message : 'An unexpected error occurred');
      } finally {
        setIsLoading(false);
      }
    };

    loadInitialCards();
  }, [activeCategories]);

  useEffect(() => {
    const handleScroll = () => {
      if (
        window.innerHeight + document.documentElement.scrollTop >=
        document.documentElement.offsetHeight - 100
      ) {
        if (page < totalPages && !isLoading) {
          loadMoreCards();
        }
      }
    };

    window.addEventListener('scroll', handleScroll);
    return () => window.removeEventListener('scroll', handleScroll);
  }, [page, totalPages, isLoading]);

  const loadMoreCards = async () => {
    if (isLoading || page >= totalPages) return;

    setIsLoading(true);
    try {
      const nextPage = page + 1;
      const { posts, currentPage, totalPages: newTotalPages } = await fetchCards(nextPage);
      if (posts.length > 0) {
        setCards(prevCards => [...prevCards, ...posts]);
        setPage(currentPage);
        setTotalPages(newTotalPages);
      }
    } catch (err) {
      setError(err instanceof Error ? err.message : 'An unexpected error occurred');
    } finally {
      setIsLoading(false);
    }
  };

  if (error) {
    return <div>Error: {error}</div>;
  }

  function formatTimeAgo(date: string): string {
    const now = new Date();
    const pastDate = new Date(date);
    const diffInSeconds = Math.floor((now.getTime() - pastDate.getTime()) / 1000);
    
    if (diffInSeconds < 60) return 'just now';
    if (diffInSeconds < 3600) return `${Math.floor(diffInSeconds / 60)} minutes ago`;
    if (diffInSeconds < 86400) return `${Math.floor(diffInSeconds / 3600)} hours ago`;
    if (diffInSeconds < 2592000) return `${Math.floor(diffInSeconds / 86400)} days ago`;
    if (diffInSeconds < 31536000) return `${Math.floor(diffInSeconds / 2592000)} months ago`;
    
    return `${Math.floor(diffInSeconds / 31536000)} years ago`;
  }

  return (
    <div className="container mx-auto px-4">
      <div className="columns-1 md:columns-2 lg:columns-3 gap-6">
        <AnimatePresence>
          {cards.map((card) => (
            <motion.div
              key={card.id}
              className="break-inside-avoid mb-6"
              initial={{ opacity: 0, y: 20 }}
              animate={{ opacity: 1, y: 0 }}
              exit={{ opacity: 0, y: -20 }}
              transition={{ duration: 0.3 }}
            >
              <Link to={`/i/${card.id}`}>
                <Card className="flex flex-col h-full transition-transform duration-300 hover:-translate-y-1">
                  <Button 
                  variant="ghost" 
                  size="sm" 
                  className="absolute top-2 right-2 opacity-50 hover:opacity-100 transition-opacity"
                >
                  <svg 
                    xmlns="http://www.w3.org/2000/svg" 
                    width="16" 
                    height="16" 
                    viewBox="0 0 24 24" 
                    fill="none" 
                    stroke="currentColor" 
                    strokeWidth="2" 
                    strokeLinecap="round" 
                    strokeLinejoin="round" 
                    className="lucide lucide-info"
                  >
                    <circle cx="12" cy="12" r="10"/>
                    <path d="M12 16v-4"/>
                    <path d="M12 8h.01"/>
                  </svg>
                  <span className="sr-only">Info</span>
                </Button>
                <CardHeader>
                  <CardTitle className="max-w-[80%]">{card.title}</CardTitle>
                  <div className="text-xs text-muted-foreground opacity-50 max-w-[80%] truncate">
                    {`Added ${formatTimeAgo(card.date)} by ${card.credit}`}
                  </div>
                </CardHeader>
                { card.description && (<CardContent className="flex-grow">
                  <CardDescription>{card.description}</CardDescription>
                </CardContent>)}
                {card.categories && card.categories.length > 0 && (
                  <CardFooter className="flex flex-col space-y-4">
                    <div className="flex flex-row items-start justify-start w-full gap-2 flex-wrap">
                      {card.categories && (
                        <span className={`px-2 py-1 text-[10px] font-semibold rounded-sm ${categoryColors[card.categories as keyof typeof categoryColors]} text-white`}>
                          {card.categories}
                        </span>
                      )}
                    </div>
                  </CardFooter>
                )}
              </Card>
              </Link>
            </motion.div>
          ))}
        </AnimatePresence>
      </div>
      {isLoading && <div className="mt-12 text-center text-sm text-muted-foreground">Loading more...</div>}
      {page >= totalPages && <div className="mt-12 text-center text-sm text-muted-foreground">All content loaded</div>}
    </div>
  );
}