import React from 'react';
import { Dialog, DialogContent, DialogHeader, DialogTitle, DialogTrigger } from '@/components/ui/dialog';
import { Button } from '@/components/ui/button';
import { Card, CardContent, CardFooter, CardHeader, CardTitle } from '@/components/ui/card';
import { ExternalLink } from 'lucide-react';

interface InnerViewProps {
  title: string;
  description: string;
  categories: string;
  credit: string;
  date: Date;
  url: string;
}

export function InnerView({ title, description, categories, credit, date, url }: InnerViewProps) {
  return (
    <div className="flex flex-col space-y-4">
      <a
        href={url}
        className="flex items-center justify-center text-blue-600 hover:text-blue-800 transition-colors mb-12"
      >
        Read Full Article ({url})
        <ExternalLink className="ml-2 h-4 w-4" />
      </a>
      <Card className="w-[80vw] mx-auto p-10">
        <CardContent className="flex">
          {description ? (
            <p className="text-sm text-gray-600 text-left">{description}</p>
          ) : (
            <p className="text-sm text-gray-400 text-center italic">No brief summary available</p>
          )}
        </CardContent>
      </Card>
      { credit && <a href={`https://x.com/${credit}`}><p className="flex items-center justify-center text-blue-600 hover:text-blue-800 transition-colors mb-12 text-sm">Submitted by @{credit}</p></a>}
    </div>
  );
}
