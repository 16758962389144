import React, { useRef, useEffect } from "react";
import { Card, CardContent } from "@/components/ui/card";
import { Avatar, AvatarFallback, AvatarImage } from "@/components/ui/avatar";
import { motion, useAnimationFrame, useMotionValue, useSpring, useTransform } from "framer-motion";

const testimonials = [
  {
    name: "λux",
    handle: "novasarc01",
    avatar: "https://pbs.twimg.com/profile_images/1814734555909140480/EC0JNTi6_400x400.jpg",
  },
  {
    name: "kache",
    handle: "yacineMTB",
    avatar: "https://pbs.twimg.com/profile_images/1757082182500139008/kF27Rhsz_400x400.jpg",
  },
  {
    name: "ₕₐₘₚₜₒₙ — e/acc",
    handle: "Hamptonism",
    avatar: "https://pbs.twimg.com/profile_images/1821620683149078533/4FGpwnKg_400x400.jpg",
  },
  {
    name: "cloud",
    handle: "cloud11665",
    avatar: "https://pbs.twimg.com/profile_images/1789378714741989377/Dn1uLGen_400x400.jpg",
  },
  {
    name: "Andrej Karpathy",
    handle: "karpathy",
    avatar: "https://pbs.twimg.com/profile_images/1296667294148382721/9Pr6XrPB_400x400.jpg",
  },
  {
    name: "Lelouch 👾",
    handle: "lelouchdaily",
    avatar: "https://pbs.twimg.com/profile_images/1766550867149225984/ebM_cPYZ_400x400.jpg"
  },
  {
    name: "mel",
    handle: "vmfunc",
    avatar: "https://pbs.twimg.com/profile_images/1827381577082458112/_J4xIj9t_400x400.jpg"
  },
  {
    name: "anpaure",
    handle: "anpaure",
    avatar: "https://pbs.twimg.com/profile_images/1774465839409836032/y3lzi74u_400x400.jpg"
  },
  {
    name: "naklecha",
    handle: "naklecha",
    avatar: "https://pbs.twimg.com/profile_images/1677635372770029570/0K3JhmKK_400x400.jpg"
  },
  {
    name: "Tom Dörr",
    handle: "tom_doerr",
    avatar: "https://pbs.twimg.com/profile_images/1492620842542510080/BjWHr7Et_400x400.jpg"
  }
];

export default function Component() {
  const baseVelocity = 2;
  const baseX = useMotionValue(0);
  const x = useTransform(baseX, (v) => `${wrap(-100, 0, v)}%`);
  const containerRef = useRef<HTMLDivElement>(null);

  useAnimationFrame((t, delta) => {
    const moveBy = baseVelocity * (delta / 1000);
    baseX.set(baseX.get() - moveBy);
  });

  return (
    <div className="w-full max-w-6xl mx-auto overflow-hidden">
      <div className="relative overflow-hidden">
        <div className="absolute inset-y-0 left-0 w-32 bg-gradient-to-r from-background to-transparent z-10"></div>
        <div className="absolute inset-y-0 right-0 w-32 bg-gradient-to-l from-background to-transparent z-10"></div>
        <motion.div
          ref={containerRef}
          className="flex"
          style={{ x }}
        >
          {[...testimonials, ...testimonials, ...testimonials, ...testimonials].map((testimonial, index) => (
            <a href={`https://x.com/${testimonial.handle}`} target="_blank" rel="noopener noreferrer">
            <div key={index} className="w-[350px] flex-shrink-0 mx-2 bg-white dark:bg-gray-800 rounded-xl border border-gray-200 dark:border-gray-700 shadow-sm">
              <div className="p-4">
                <div className="flex items-center space-x-3">
                  <Avatar className="h-10 w-10">
                    <AvatarImage
                      src={testimonial.avatar}
                      alt={testimonial.name}
                    />
                    <AvatarFallback className="bg-gray-200 dark:bg-gray-700 text-gray-700 dark:text-gray-200">
                      {testimonial.handle
                        .split(" ")
                        .map((n) => n[0])
                        .join("")}
                    </AvatarFallback>
                  </Avatar>
                  <div>
                    <div className="font-bold text-gray-900 dark:text-white">{testimonial.name}</div>
                    <p className="text-sm text-gray-500 dark:text-gray-400">
                      @{testimonial.handle.toLowerCase().replace(' ', '')}
                    </p>
                  </div>
                </div>
                {/* <p className="text-base text-gray-700 dark:text-gray-300 mb-2">{testimonial.content}</p> */}
              </div>
            </div>
            </a>
          ))}
        </motion.div>
      </div>
    </div>
  );
}

function wrap(min: number, max: number, v: number) {
  const rangeSize = max - min;
  return ((((v - min) % rangeSize) + rangeSize) % rangeSize) + min;
}
