import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import { ModeToggle } from './components/mode-toggle';
import { Button } from './components/ui/button';
import { SubmissionForm } from './components/ui/submissionform';
import { Logo } from './components/logo';
import InnerPage from './pages/inner';
import Home from './pages/home';
import FallbackPage from './pages/fallback';
import ReviewPage from './pages/review';

function App() {
  return (
    <Router>
      <div className="relative">
        <div className="fixed top-4 left-4 z-50">
          <Logo onClick={() => {
            if (window.location.pathname === '/') {
              window.scrollTo({ top: 0, behavior: 'smooth' });
            } else {
              window.location.href = '/';
            }
          }} />
        </div>
        <nav className="fixed top-4 right-4 flex items-center space-x-2 bg-background/80 backdrop-blur-sm shadow-md p-2 z-50 border border-border rounded-lg">
          {/* <Button variant="ghost" className="text-sm px-3 py-2 border border-border rounded-md">Tools</Button>
          <Button variant="ghost" className="text-sm px-3 py-2 border border-border rounded-md">Articles</Button>
          <Button variant="ghost" className="text-sm px-3 py-2 border border-border rounded-md">Papers</Button>
          <Button variant="ghost" className="text-sm px-3 py-2 border border-border rounded-md">Products</Button> */}
          {window.history.length > 1 && (
            <Button
              variant="ghost"
              className="text-sm px-3 py-2 border border-border rounded-md"
              onClick={() => window.history.back()}
            >
              <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" className="mr-2 h-4 w-4">
                <path d="m15 18-6-6 6-6"/>
              </svg>
              Back
            </Button>
          )}
          <SubmissionForm />
          <ModeToggle />
        </nav>
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/i/:id" element={<InnerPage />} />
          <Route path="/r/" element={<ReviewPage />} />
          <Route path="*" element={<FallbackPage />} />
        </Routes>
      </div>
    </Router>
  );
}

export default App;
