import React, { useState, useEffect } from 'react';
import { Card, CardContent, CardFooter, CardHeader, CardTitle } from '@/components/ui/card';
import { Button } from '@/components/ui/button';
import { ThumbsUp, ThumbsDown } from 'lucide-react';

interface Submission {
  id: string;
  title: string;
  url: string;
  description?: string;
  categories?: string;
  credit?: string;
  date: string;
}

export default function ReviewPage() {
  const [submissions, setSubmissions] = useState<Submission[]>([]);
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState<string | null>(null);

  useEffect(() => {
    fetchSubmissions();
  }, []);

  async function fetchSubmissions() {
    try {
      const response = await fetch('https://api.playpanzer.com/mlboard/submissions');
      if (!response.ok) {
        throw new Error('Failed to fetch submissions');
      }
      const data = await response.json();
      setSubmissions(data);
    } catch (err) {
      setError('An error occurred while fetching submissions');
      console.error(err);
    } finally {
      setIsLoading(false);
    }
  }

  async function handleApprove(id: string) {
    try {
      const response = await fetch(`https://api.playpanzer.com/mlboard/approve/${id}`, {
        method: 'POST',
      });
      if (!response.ok) {
        throw new Error('Failed to approve submission');
      }
      setSubmissions(submissions.filter(sub => sub.id !== id));
    } catch (err) {
      console.error(err);
      // Handle error (e.g., show a toast notification)
    }
  }

  async function handleDecline(id: string) {
    try {
      const response = await fetch(`https://api.playpanzer.com/mlboard/decline/${id}`, {
        method: 'POST',
      });
      if (!response.ok) {
        throw new Error('Failed to decline submission');
      }
      setSubmissions(submissions.filter(sub => sub.id !== id));
    } catch (err) {
      console.error(err);
      // Handle error (e.g., show a toast notification)
    }
  }

  if (isLoading) {
    return <div className="text-center mt-8">Loading submissions...</div>;
  }

  if (error) {
    return <div className="text-center mt-8 text-red-500">{error}</div>;
  }

  return (
    <div className="container mx-auto p-4 mt-20">
      <h1 className="text-2xl font-bold mb-6">Review Submissions</h1>
      <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-4">
        {submissions.map((submission) => (
          <Card key={submission.id} className="flex flex-col">
            <CardHeader>
              <CardTitle>{submission.title}</CardTitle>
            </CardHeader>
            <CardContent className="flex-grow">
              <p className="text-sm text-gray-600 mb-2">{submission.url}</p>
              {submission.description && (
                <p className="text-sm">{submission.description}</p>
              )}
              <div className="mt-2">
                  <span className="inline-block bg-gray-200 rounded-full px-3 py-1 text-sm font-semibold text-gray-700 mr-2 mb-2">
                    {submission.categories}
                  </span>
              </div>
              {submission.credit && (
                <p className="text-sm text-gray-500 mt-2">Submitted by: {submission.credit}</p>
              )}
              <p className="text-sm text-gray-500 mt-2">Date: {new Date(submission.date).toLocaleDateString()}</p>
            </CardContent>
            <CardFooter className="flex justify-between">
              <Button onClick={() => handleApprove(submission.id)} className="flex items-center">
                <ThumbsUp className="mr-2 h-4 w-4" /> Approve
              </Button>
              <Button onClick={() => handleDecline(submission.id)} variant="destructive" className="flex items-center">
                <ThumbsDown className="mr-2 h-4 w-4" /> Decline
              </Button>
            </CardFooter>
          </Card>
        ))}
      </div>
    </div>
  );
}
