import { useState } from 'react';
import { Button } from '@/components/ui/button';
import SponsorshipModal from '@/components/ui/sponsor';
import SocialProof from '@/components/ui/socialproof';
import { CategoryFilter } from '@/components/ui/categoryfilter';
import CardGrid from '@/components/ui/cardgrid';
import {Helmet} from "react-helmet";

export default function HomePage() {
    const [showCategoryFilter, setShowCategoryFilter] = useState(true);

    const categoryNames = [
      "Natural Language Processing",
      "Machine Learning",
      "Computer Vision",
      "Deep Learning",
      "Reinforcement Learning",
      "Robotics",
      "Generative AI",
      "Neural Networks",
      "Speech Recognition",
      "Image Processing",
      "Data Mining",
      "Artificial Intelligence",
      "Expert Systems",
      "Natural Language Generation",
      "Chatbots",
      "Autonomous Vehicles",
    ];

    const [activeCategories, setActiveCategories] = useState<string[]>(categoryNames);
  
    const toggleCategoryFilter = () => {
      setShowCategoryFilter((prev) => !prev);
    };
  
    const onFilterChange = (selectedCategories: string[]) => {
      setActiveCategories(selectedCategories);
    };
  
    const enableAllCategories = () => {
      setActiveCategories(categoryNames);
    };
  
    const disableAllCategories = () => {
      setActiveCategories([]);
    };
  
    return (
      <div className="[&>section]:w-full [&>section]:py-[20dvh] lg:[&>section]:min-h-[75dvh] overflow-x-hidden">
        <Helmet>
          <title>MLBoard</title>
          <meta name="description" content="MLBoard is a comprehensive resource for machine learning, artificial intelligence, and deep learning." />
          <meta property="og:title" content="MLBoard" />
          <meta property="og:description" content="MLBoard is a comprehensive resource for machine learning, artificial intelligence, and deep learning." />
          <meta property="og:type" content="website" />
          <meta property="og:url" content={window.location.href} />
          <meta name="twitter:card" content="summary_large_image" />
          <meta name="twitter:title" content="MLBoard" />
          <meta name="twitter:description" content="MLBoard is a comprehensive resource for machine learning, artificial intelligence, and deep learning." />
          <meta name="keywords" content="ai tools, ml tools, machine learning tools, ai tools, new ai tools, new ml tools" />
        </Helmet>
        <section className="flex flex-col flex-center space-y-16">
          <h1 className="leading-tight text-center max-w-screen-lg mx-auto mt-0 sm:-mt-20 px-4">
            <span>
              <span className="sr-only">
                Comprehensive resource for machine learning, artificial intelligence, and deep learning.
              </span>{" "}
              <span className="text-4xl tracking-tighter font-bold relative z-10">What's happening in the ML ecosystem?</span>
            </span>
          </h1>
          <div className="flex justify-center">
            <SponsorshipModal />
          </div>
          <SocialProof />
          <div className="container mx-auto px-4">
            <div className="flex flex-col sm:flex-row gap-2 mb-4">
              <Button onClick={toggleCategoryFilter} className="text-xs" variant="outline">
                {showCategoryFilter ? 'Hide Category Filter' : 'Show Category Filter'}
              </Button>
              {showCategoryFilter && (
                <div className="mt-2 sm:mt-0 sm:ml-auto flex flex-col sm:flex-row gap-2">
                  <div className="flex items-center text-[10px] text-muted-foreground/70 sm:ml-auto">
                    <span>{activeCategories.length} / {categoryNames.length} categories enabled</span>
                  </div>
                  <Button onClick={enableAllCategories} className="text-xs" variant="outline">
                    Enable All
                  </Button>
                  <Button onClick={disableAllCategories} className="text-xs bg-gray-800 text-white hover:bg-gray-700 hover:text-white" variant="outline">
                    Disable All
                  </Button>
                </div>
              )}
            </div>
            {showCategoryFilter && <CategoryFilter onFilterChange={onFilterChange} activeCategories={activeCategories} />}
          </div>
          <CardGrid activeCategories={activeCategories} />
        </section>
      </div>
    );
  }